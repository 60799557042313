<template lang="pug">
  label(
    :for="id"
    :class="computedClassGeneral"
  ).rounded-bd-small.block.relative.transition.duration-500
    //- slot(name="prepend")
    .absolute.iconpp
      slot
    select(
      v-if="mode === 'select'"
      :id="id"
      :value="value"
      @input="updateValue($event.target.value)"
      :name="name"
      :required="required"
      autofocus="true"
    ).border-none.outline-none.bg-transparent.w-full.relative.inputStyles.font-normal.p-2.px-1.selectStyles.z-10
      option(
        v-for="(option, indexOption) in options"
        :key="indexOption"
        :selected="option.selected"
        :value="option.name"
      ) {{ option.name }}
    input(
      v-else-if="mode === 'input'"
      :type="type"
      :id="id"
      :value="value"
      :placeholder="placeholder"
      @change="handleChangeLocal"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="updateValue($event.target.value)"
      :disabled="disabled"
      :name="name"
      :readonly="readonly"
      :required="required"
      :class="type === 'number' && 'numberInput'"
      autocomplete="off"
    ).border-none.outline-none.bg-transparent.w-full.relative.inputStyles.font-normal.z-10.mt-2
    input(
      v-else-if="mode === 'checkbox'"
      type="checkbox"
      :id="id"
      :checked="value"
      :placeholder="placeholder"
      @input="updateValue($event.target.checked)"
      :disabled="disabled"
      :name="name"
      :required="required"
      :autocapitalize="autocapitalize"
    ).border-none.outline-none.bg-transparent.w-full.relative.inputStyles.font-normal.z-10.mt-2.w-4.h-4
    p(v-if="label" :class="value ? 'labelStylesActive' : ''").absolute.labelStyles.text-info_color.text-md.truncate.w-full.max-w-full
      | {{ label }}
</template>

<script>
export default {
  name: "Input",
  props: {
    mode: String,
    appendMore: {
      default: false,
      type: Boolean,
    },
    options: {
      type: Array,
    },
    type: {
      default: "text",
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      default: "",
      type: String,
    },
    name: {
      default: "",
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
    },
    placeholder: {
      default: "",
      type: String,
    },
    autocapitalize: String,
    onChange: Function,
  },
  computed: {
    computedClassGeneral() {
      return (
        (this.disabled && "opacity-50") +
        (this.label && this.mode !== "checkbox" && " pt-3 ") +
        (this.mode === "select" ? "" : this.mode === "input" && this.label ? " pt-5 p-1 px-3 " :
          this.mode === "input" && !this.label ? " pt-1 pb-2 px-3 " : "") +
        (this.mode !== "checkbox" ? " bg-white max-w-sm w-full generalLabel mx-auto mt-4" : "bg-white w-4")
      );
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    handleChangeLocal(event) {
      if (this.onChange) {
        this.onChange(event);
      }
    },
    handleFocus() {
      // if( navigator.userAgent.match(/Android/i)
      // || navigator.userAgent.match(/webOS/i)
      // || navigator.userAgent.match(/iPhone/i)
      // || navigator.userAgent.match(/iPad/i)
      // || navigator.userAgent.match(/iPod/i)
      // || navigator.userAgent.match(/BlackBerry/i)
      // || navigator.userAgent.match(/Windows Phone/i)) {
      //   const $Footer = document.querySelector(".Footer.absolute");
      //   const $MainContent = document.querySelector(".Main.content");

      //   if ($Footer && !event.target.readOnly) {
      //     $Footer.classList.add("FooterHide");
      //     if ($MainContent) {
      //       $MainContent.classList.remove("contentImportant");
      //       $MainContent.classList.remove("addMarginTop");
      //       $MainContent.classList.remove("content");
      //     }
      //   }
      // }
    },
    handleBlur() {
      // if( navigator.userAgent.match(/Android/i)
      // || navigator.userAgent.match(/webOS/i)
      // || navigator.userAgent.match(/iPhone/i)
      // || navigator.userAgent.match(/iPad/i)
      // || navigator.userAgent.match(/iPod/i)
      // || navigator.userAgent.match(/BlackBerry/i)
      // || navigator.userAgent.match(/Windows Phone/i)) {
      //   const $Footer = document.querySelector(".Footer.absolute");
      //   const $MainContent = document.querySelector(".Main");

      //   if ($Footer) {
      //     $Footer.classList.remove("FooterHide");
      //     if ($MainContent) {
      //       $MainContent.classList.add("contentImportant");
      //       $MainContent.classList.add("addMarginTop");
      //       $MainContent.classList.add("content");
      //     }
      //   }
      // }
    },
  },
}
</script>

<style scoped>
.generalLabel {
  border-bottom: 2px solid rgb(219, 217, 217);
}

.generalLabel:hover {
  border-bottom: 2px solid rgb(194, 235, 240);
}

.labelStyles {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  transition: 0.23s all ease-in-out;
}

.inputStyles {
  background: none;
  font-size: 15.5px;
}

.selectStyles {
  height: 60px;
  margin-top: -21px;
  padding-top: 32px;
}

.inputStyles:focus + .labelStyles {
  color: #2E6AB3;
  transform: translateY(-100%) translateX(-40px) scale(0.8);
}

/* .inputStyles:focus {
  border-bottom: 2px solid #2e6ab357;
} */

.FooterHide {
  opacity: 0;
  transition: 1s all;
}

.labelStylesActive {
  color: #2E6AB3;
  transform: translateY(-100%) translateX(-40px) scale(0.8);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

select > option {
  font-size: 13px;
}

.iconpp {
  right: 5px;
  top: 60%;
  transform: translateY(-50%);
}
</style>
